import React, { useState } from "react";
import burger_menu_icon from "../assets/burger-menu-svgrepo-com.svg";
import { Link } from "react-router-dom";

const HamburgerDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ marginLeft: "8px" }}>
      <button onClick={handleClick}>
        <img
          src={burger_menu_icon}
          draggable='false'
          alt='hamburger-menu'
          style={{
            height: "50px",
            width: "50px",
            position: "relative",
          }}
        />
      </button>
      {isOpen && (
        <div
          className='dropdown-menu'
          style={{
            position: "absolute",
            zIndex: "10000000",
            backgroundColor: "white",
          }}
        >
          <Link to='/map' style={{ textDecoration: "none", color: "black" }}>
            <p style={{ fontSize: "30px" }}>Map</p>
          </Link>
          <Link to='/lookup' style={{ textDecoration: "none", color: "black" }}>
            <p style={{ fontSize: "30px" }}>Works</p>
          </Link>
          <Link to='/about' style={{ textDecoration: "none", color: "black" }}>
            <p style={{ fontSize: "30px" }}>About</p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default HamburgerDropdown;

// import React, { useState } from 'react';

// const MobileMenu = () => {
//   const [isMenuOpen, setMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div className="mobile-menu">
//       <div className="hamburger-icon" onClick={toggleMenu}>
//         ☰ {/* You can replace this with your hamburger icon */}
//       </div>

//       {isMenuOpen && (
//         <div className="menu-dropdown">
//           {/* Add your menu items here */}
//           <div className="menu-item">Item 1</div>
//           <div className="menu-item">Item 2</div>
//           <div className="menu-item">Item 3</div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MobileMenu;
