import React from "react";
import pollockSmoking from "../assets/pollock_smoking.png";

const PageNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <img alt='pollock_404' src={pollockSmoking} />
      <h1>404 Page Not Found</h1>
    </div>
  );
};
export default PageNotFound;
