import "../CSS/App.css";
import React, { useEffect, useRef, useState } from "react";
import { firestore, storage } from "../firebase_setup/firebase.config";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import * as yup from "yup";
import { v4 } from "uuid";
import { UserAuth } from "../context/Auth";
import ButtonOrganizer from "./ButtonOrganizer";

export const ArtworkInput = () => {
  const { user } = UserAuth();

  const dataRef = collection(firestore, "pollockWorks");
  const museumRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const countryRef = useRef();
  const titleRef = useRef();
  const yearRef = useRef();
  const mediumRef = useRef();
  const viewingRef = useRef();
  const contactRef = useRef();
  const linkRef = useRef();
  const dimsRef = useRef();
  const miscRef = useRef();
  const catalogRef = useRef();

  const [isOnView, setIsOnView] = useState(false);
  const [error, setError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [miscInfo, setMiscInfo] = useState("");

  const artworkInputSchema = yup.object().shape({
    title: yup.string().min(1).max(255),
  });

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const submithandler = async (e) => {
    e.preventDefault();

    const randomNumber = v4();
    const imagesRef = ref(storage, `images/${randomNumber}`);

    let pollockObject = {
      isArtwork: true,
      museum: museumRef.current?.value,
      city: cityRef.current?.value,
      state: stateRef.current?.value,
      country: countryRef.current?.value,
      title: titleRef.current?.value,
      year: yearRef.current?.value,
      onView: isOnView,
      catalog: catalogRef.current?.value,
      medium: mediumRef.current?.value,
      dimensions: dimsRef.current?.value,
      contact: contactRef.current?.value,
      viewing: viewingRef.current?.value,
      link: linkRef.current?.value,
      miscInfo: miscRef.current?.value,
      copyRight: "Copyright - Pollock Krasner Foundation/Artists Rights Society (ARS), New York",
    };
    const formIsValid = await artworkInputSchema.isValid(pollockObject);

    if (formIsValid) {
      try {
        setLoading(true);

        const returnedBytes = await uploadBytes(imagesRef, selectedImage);

        setLoading(false);
        pollockObject.imageBucket = returnedBytes ? returnedBytes.ref.bucket : "";
        pollockObject.imagePath = returnedBytes ? returnedBytes.ref.fullPath : "";
        addDoc(dataRef, pollockObject);
        museumRef.current.value = "";
        cityRef.current.value = "";
        stateRef.current.value = "";
        countryRef.current.value = "";
        titleRef.current.value = "";
        yearRef.current.value = "";
        setIsOnView(false);
        catalogRef.current.value = "";
        mediumRef.current.value = "";
        contactRef.current.value = "";
        viewingRef.current.value = "";
        linkRef.current.value = "";
        dimsRef.current.value = "";
        miscRef.current.value = "";
        setSelectedImage(null);
        setMiscInfo("");
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Please enter a valid title");
    }
  };

  return (
    <div className='input-body'>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Pollock Project</h1>
        <div style={{ marginTop: 20, padding: 20 }}>
          <ButtonOrganizer />
        </div>
      </div>
      <p style={{ paddingBottom: 20, paddingLeft: 20 }} id='logged-in-as'>
        Logged in as {user.email}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            paddingBottom: 20,
            paddingLeft: 20,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <form onSubmit={submithandler}>
            <div style={{ paddingBottom: 20 }} className='museum'>
              <input size='50' type='text' id='museum' placeholder='Museum' ref={museumRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='city'>
              <input size='50' type='text' id='city' placeholder='City' ref={cityRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='state'>
              <input size='50' type='text' id='state' placeholder='State' ref={stateRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='country'>
              <input size='50' type='text' id='country' placeholder='Country' ref={countryRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='title'>
              <input size='50' type='text' id='title' placeholder='Title' ref={titleRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='year'>
              <input size='50' type='text' id='year' placeholder='Year' ref={yearRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='medium'>
              <input size='50' type='text' id='medium' placeholder='Medium' ref={mediumRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='heightWidth'>
              <input size='50' type='text' id='heightWidth' placeholder='Height x Width' ref={dimsRef} />
            </div>

            <div style={{ paddingBottom: 20 }}>
              <label>Add an image:</label>
              <input
                name='fileinput'
                type='file'
                style={{ color: "white" }}
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </div>
            <div style={{ paddingBottom: 20 }} className='onview'>
              <label>On View</label>
              <input
                type='checkbox'
                id='onview'
                checked={isOnView}
                name='On View'
                onChange={() => setIsOnView(!isOnView)}
                value={isOnView}
                size='10'
              />
            </div>
            <div style={{ paddingBottom: 20 }} className='catalogNumber'>
              <input size='50' type='text' id='catalogNumber' placeholder='CR #' ref={catalogRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='viewing'>
              <input id='viewing' size='50' type='text' placeholder='Viewing' ref={viewingRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='contact'>
              <input id='contact' size='50' type='text' placeholder='Contact' ref={contactRef} />
            </div>
            <div style={{ paddingBottom: 20 }} className='link'>
              <input id='link' size='50' type='text' placeholder='Link' ref={linkRef} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: 20,
              }}
            >
              <label style={{ paddingBottom: 4 }}>Misc Info: </label>
              <textarea value={miscInfo} onChange={(e) => setMiscInfo(e.target.value)} ref={miscRef} />
            </div>
            <div className='sumbit'>
              <button type='submit'>Submit</button>
              {error && <h2 style={{ marginTop: 20, color: "red" }}>{error}</h2>}
              {loading && <h2 style={{ marginTop: 20, color: "red" }}>PLEASE WAIT! Submit in progress.</h2>}
            </div>
          </form>

          {imageUrl && selectedImage && (
            <div style={{ marginLeft: 50 }}>
              <img src={imageUrl} alt={selectedImage.name} height='700px' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ArtworkInput;
