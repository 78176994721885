import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/Auth";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ArtworkInput from "./components/ArtworkInput";
import ArtworkLookUp from "./components/ArtworkLookUp";
import LandingPage from "./components/LandingPage";
import { AnimatePresence } from "framer-motion";
import About from "./components/About";
import Whole from "./components/Whole";
import Map2 from "./components/Map2";
import PageNotFound from "./components/PageNotFound";
import AllArtworks from "./components/AllArtworks";

export const App = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait'>
      <AuthProvider>
        <Routes location={location} key={location.pathname}>
          <Route path='/signin' element={<SignIn />} />
          <Route path='/' element={<LandingPage />} />
          <Route path='/landing' element={<LandingPage />} />
          <Route path='/lookup' element={<ArtworkLookUp />} />
          <Route path='/whole' element={<Whole />} />
          <Route path='/about' element={<About />} />
          <Route path='/map' element={<Map2 />} />
          <Route path='/404' element={<PageNotFound />} />
          <Route path='*' element={<Navigate to='/404' />} />
          <Route
            path='/signup'
            element={
              <PrivateRoute path='/signup'>
                <SignUp />
              </PrivateRoute>
            }
          />
          <Route
            path='/input'
            element={
              <PrivateRoute path='/input'>
                <ArtworkInput />
              </PrivateRoute>
            }
          />
          <Route
            path='/allartworks'
            element={
              <PrivateRoute path='/allartworks'>
                <AllArtworks />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </AnimatePresence>
  );
};
export default App;
