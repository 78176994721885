import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { UserAuth } from "../context/Auth";

export const PrivateRoute = ({ children }) => {
  const { user } = UserAuth();
  const location = useLocation();
  console.log("user: ", user);
  if (user === null) {
    return <Navigate to='/signin' replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
