import "../CSS/App.css";
import React from "react";
import PollockProjectLogo from "./PollockProjectLogo";
import LogoutButton from "./LogoutButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/Auth";
import SamImageBlackWhite from "../assets/Samuel_Seymour_Portrait_BW.jpg";
import NewsletterInput from "./NewsletterInput";

export const SignIn = () => {
  const [email, updateEmail] = useState("");
  const [error, updateError] = useState("");
  const [password, updatePassword] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateError("");
    try {
      await signIn(email, password);
      navigate("/landing");
    } catch (error) {
      updateError("Error logging in incorrect password or email");
    }
  };

  return (
    <div>
      <div
        className='sign-in-container'
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: "30px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div>
            <form onSubmit={handleSubmit}>
              <div style={{ paddingTop: 20, paddingBottom: 10, paddingLeft: 20 }} className='sign-in-email'>
                <input
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => updateEmail(e.target.value)}
                ></input>
              </div>
              <div style={{ paddingLeft: 20 }} className='sign-in-password'>
                <input
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => updatePassword(e.target.value)}
                ></input>
              </div>
              <div
                style={{
                  height: "20px",
                  paddingLeft: "20px",
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button type='submit'>Log In</button>
                <LogoutButton />
              </div>
              <div style={{ paddingLeft: "20px" }}>
                {error && <h style={{ color: "red", marginTop: 20 }}>{error}</h>}
              </div>
            </form>
          </div>
          <div style={{ paddingRight: "22px" }}>
            <PollockProjectLogo />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            <img src={SamImageBlackWhite} alt='' width='190px' height='190px' style={{ padding: "20px" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "20px",
              }}
            >
              <p
                style={{
                  fontFamily: '"Times New Roman", Times, serif',
                  fontSize: "14px",
                }}
              >
                <span style={{ fontFamily: "Helvetica", fontSize: "12px" }}>
                  The<b>Pollock</b>Project
                </span>{" "}
                is my multi-year journey, by van, to visit each of Jackson Pollock’s publicly displayed artworks
                throughout the United States.
              </p>
              <br />
              <p
                style={{
                  fontFamily: '"Times New Roman", Times, serif',
                  fontSize: "14px",
                }}
              >
                This site will host an interactive map illustrating the public collections of Pollock’s work across the
                States and along with a comprehensive database of his works.
              </p>
              <br />
              <p
                style={{
                  fontFamily: '"Times New Roman", Times, serif',
                  fontSize: "14px",
                }}
              >
                To stay updated on the site and posted on my travels with some commentary along the way, please sign up
                for my mailing list.
              </p>
              <br />
              <p
                style={{
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  marginBottom: "4px",
                }}
              >
                — Sam Seymour
              </p>
              <p>
                <a
                  href='mailto:sam@thepollockproject.com'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                  }}
                >
                  sam@thepollockproject.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            position: "fixed",
            bottom: "0",
            right: "0",
            zIndex: 1,
            paddingBottom: "50px",
            paddingRight: "300px",
          }}
        >
          <NewsletterInput />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
